<template>
  <div class="animated fadeIn">
<!--
    <b-button variant="danger" :active="true" @click="Monthly()">Monthly</b-button>
    <b-button variant="primary" :active="true" @click="Weekly()">Weekly</b-button>
    <b-button variant="success" :active="true" @click="Daily()">Daily</b-button>
-->
    <b-card :title="$t('message.title.incomePerMc')">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        {{ $t('message.machineId') }} : <br>
        <model-list-select 
          :list="machineData"
          v-model="searchData.id"
          option-value="machineId"
          option-text="name"
          placeholder="Select Machine ID">
         </model-list-select>
      </div>
      <div class="col-sm-6 col-md-3">
        {{ $t('message.date') }} : <br>
        <date-picker 
          range
          type="datetime" 
          v-model="date" 
          lang="en" 
          format="yyyy-MM-dd" 
          width=220>
        </date-picker>
        
<!--
        <list-select 
          :list="machineData"
          :selected-item="selectedId"
          placeholder="Select Machine ID"
          option-value="machineId"
          option-text="machineId"
          @select="onSelect"
        />
-->
        
      </div>
<!--
      <div class="col-sm-6 col-md-3">
        ประเภทการค้นหา :
        <b-form-select v-model="searchData.by" :options="by_select"/></td>
      </div>
-->
      <div class="col-sm-6 col-md-3">
        <br><b-button v-on:click="searchFn()" variant="primary"><i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}</b-button>
        <b-button v-on:click="ExportFn(rowData)" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t('message.export') }}</b-button>
<!--
        <download-excel
        class   = "btn btn-success"
        @click = "test()"
        :title = "title"
        :data   = "rowData"
        :fields = "exportField"
        :name    = "reportFilename">
        Export
        <i class="icon-docs"></i>
        </download-excel>
-->
      </div>
    </div>
    <div class="wrapper">
      <br><b-list-group-item>
        <div class="col-12">
          <div class="table-responsive" style="top:15px;" ><br>
            <table class="table2" v-if="rowData !== ''"> 
              <thead>
                <tr>
                  <th>{{ $t('message.productId') }} <i class="fa fa-sort-amount-asc" @click="sortFunction('id')"></i></th>
                  <th>{{ $t('message.productName') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('qty')"></i> {{ $t('message.amount') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('price')"></i> {{ $t('message.sale') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('acceptorMoney')"></i> {{ $t('message.acceptorMoney') }}</th>
                </tr>
              </thead>
              <template v-for="item in rowData">
                <tr v-if="item.product">
                  <td>{{item.productId}}</td>
                  <td>{{item.product.name}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.qty)}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.price)}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.acceptorMoney)}}</td>
                </tr>
              </template>
              <tfoot>
                <tr>
                  <th></th>
                  <th>{{ $t('message.sum') }}</th>
                  <th style="text-align: right">{{totalQty}}</th>
                  <th style="text-align: right">{{totalPrice}}</th>
                  <th style="text-align: right">{{totalAcceptorMoney}}</th>
                </tr>
              </tfoot>
            </table>
            <template v-else><p style="text-align: center">{{ $t('message.transactionNotFound') }}</p></template>
<!--            <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
          </div>
        </div>
      </b-list-group-item>
    </div>
    </b-card>
    <b-modal ref="exportModal" :title="$t('message.modalTitle.summary')" @hidden="closeExportModal()">
      <table class="table table no-border table-responsive table-sm">
        <tr>
          <td>{{ $t('message.date') }}</td>
          <td>{{this.searchData.start}} - {{this.searchData.end}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.machineId') }}</td>
          <td>{{searchData.id}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.amount') }}</td>
          <td>{{totalQty}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.sale') }}</td>
          <td>{{totalPrice}}</td>
        </tr>
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel
          class   = "btn btn-success float-right"
          :title = "title"
          :data   = "exportData"
          :fields = "exportField"
          :name    = "reportFilename">
        Export
        <i class="icon-docs"></i>
        </download-excel>
<!--        <b-btn variant="danger" class="float-left" @click="closeExportModal()">Close</b-btn>  -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../../script'
import DatePicker from '../../custom_modules/vue2-datepicker'
import { ListSelect, ModelListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
export default {
  name: 'SalesByMachine',
  components: {
    DatePicker,
    ListSelect,
    ModelListSelect
  },
  data () {
    return {
      totalRows: 1,
      date: '',
      machineData: [],
      rowData: [],
      searchData: {
        by: 'product',
        id: ''
      },
      totalQty: '',
      totalPrice: '',
      totalAcceptorMoney: '',
      selectedId: {},
      by_select: [
        {value: 'product', text: 'product'},
        {value: 'slot', text: 'slot'}
      ],
      exportField: {
        รหัสสินค้า: 'productId',
        sku: 'product.sku',
        ชื่อสินค้า: 'product.name',
        จำนวน: 'qty',
        ยอดขาย: 'price'
      },
      webServices: webServices,
      title: [],
      reportFilename: '',
      exportData: []
    }
  },
  methods: {
    getMachine () {
      this.$Progress.start()
      webServices.getMachineList(window.localStorage.getItem('userId')).then((res) => {
        this.$Progress.finish()
        this.machineData = res.data
        // console.log(res.data)
      }).catch((err) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        this.$toast.error({
          title: 'ERROR',
          message: {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
        })
        console.log('error @machine')
        console.log(err)
      })
    },
    onSelect (item) {
      this.selectedId = item
      this.searchData.id = item.machineId
    },
    searchFn () {
      this.$Progress.start()
      if (this.searchData.id === '' || this.date === '') {
        this.$toast.error({
          title: 'ERROR',
          message: 'Please select date and machine ID'
        })
      } else {
        var start = this.date[0]
        var end = this.date[1]
        var timeEnd = this.date[1].toLocaleTimeString()
        if (timeEnd === '00:00:00') {
          end.setHours(23, 59, 59, 999)
        }
        this.searchData.start = moment(start).format('YYYY-MM-DD HH:mm:ss')
        this.searchData.end = moment(end).format('YYYY-MM-DD HH:mm:ss')
        webServices.getReportByProduct(this.searchData).then((res) => {
          this.$Progress.finish()
          this.rowData = res.data.row
          console.log(res.data)
          this.totalPrice = webServices.addCommas(res.data.totalAmount)
          this.totalQty = webServices.addCommas(res.data.totalQty)
          this.totalAcceptorMoney = webServices.addCommas(res.data.totalAcceptorMoney)
          this.title[0] = `หมายเลขตู้ ${this.searchData.id}`
          this.title[1] = `วันที่ ${this.searchData.start} ถึง ${this.searchData.end}`
          this.reportFilename = `Report_${this.searchData.id}_${moment(this.searchData.start).format('YYYY-MM-DD')}_to_${moment(this.searchData.end).format('YYYY-MM-DD')}.xls`
          // this.searchData.id = ''
        }).catch((err) => {
          this.$Progress.fail()
          console.log('Error @report by machine')
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
      }
    },
    sortFunction (type) {
      if (type === 'qty') {
        this.rowData.sort(function (a, b) { return (a.product.name === 'สรุป' || b.product.name === 'สรุป') ? 1 : (a.qty < b.qty) ? 1 : ((b.qty < a.qty) ? -1 : 0) })
        // this.rowData.sort(function (a, b) { return (a.qty < b.qty) ? 1 : (((b.qty < a.qty) || (a.product.name === 'สรุป')) ? -1 : 0) })
      } else if (type === 'price') {
        this.rowData.sort(function (a, b) { return (a.product.name === 'สรุป' || b.product.name === 'สรุป') ? 1 : (Number(a.price) < Number(b.price)) ? 1 : ((Number(b.price) < Number(a.price)) ? -1 : 0) })
      } else if (type === 'id') {
        this.rowData.sort(function (a, b) { return (a.product.name === 'สรุป' || b.product.name === 'สรุป') ? 1 : (a.productId > b.productId) ? 1 : ((b.productId > a.productId) ? -1 : 0) })
      }
    },
    ExportFn (data) {
      if (this.rowData.length > 0) {
        // console.log('push sum')
        this.exportData = data
        this.exportData.push({
          productId: '',
          product: {
            sku: '',
            name: 'สรุป'
          },
          qty: this.totalQty,
          price: this.totalPrice,
          acceptorMoney: this.totalAcceptorMoney
        })
      }
      this.$refs.exportModal.show()
    },
    closeExportModal () {
      // console.log('close')
      this.exportData.pop()
      this.$refs.exportModal.hide()
    }
  },
  mounted () {
    this.getMachine()
  }
}
</script>

<style>
  @import './styleRe.css'
</style>